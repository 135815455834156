import { ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { formatValue as phoneNumber } from 'components/common/form/phone-number-field/helper';
import { isEmpty } from 'utils/is';
import { format as dateFormat } from 'date-fns';
import { ValidDate } from 'types';

const defaultDateFormat = 'yyyy-MM-dd';

const date = (value: ValidDate, format = defaultDateFormat): string => dateFormat(new Date(value), format);

const chartDate = (value: ValidDate): string => date(value, 'dd LLLL, yyyy');

const dateRange = ({
  from,
  to,
  format,
  placeholder = <Trans i18nKey='Common.EmptyValue' />,
}: { from?: Nullable<ValidDate>; to?: Nullable<ValidDate> } & {
  format?: string;
  placeholder?: ReactNode;
}): string | ReactNode => {
  const dates = [from, to].filter((value) => !isEmpty(value)) as Date[];

  return dates.map((value) => date(value, format)).join(' - ') || placeholder;
};

const dateFrom = ({
  date,
  placeholder = <Trans i18nKey='Common.EmptyValue' />,
}: { date?: string | number | Date | null | undefined } & {
  format?: string;
  placeholder?: ReactNode;
}): string | ReactNode => {
  const dateObject = date ? new Date(date) : null;

  const year = `${dateObject && dateObject.getFullYear()}`;
  const month = `0${dateObject && dateObject.getMonth() + 1}`.slice(-2);
  const day = `0${dateObject && dateObject.getDate()}`.slice(-2);

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate || placeholder;
};

const currency = (
  value: number,
  options: Intl.NumberFormatOptions = { style: 'currency', currency: 'USD' },
  locale = 'en',
): string => new Intl.NumberFormat(locale, options).format(value);

const toDollars = (cents: number | string = 0): string => currency(Number(cents) / 100 || 0);

const toPercentage = (rate = 0): string => `${rate * 100}%`;

const toNumberWithComma = (
  value?: number,
  { format = 'en', placeholder = '0' }: { format?: string; placeholder?: any } = {},
): string => (!value && !isEmpty(placeholder) ? placeholder : new Intl.NumberFormat(format).format(value as number));

export { date, dateRange, dateFrom, toDollars, toPercentage, toNumberWithComma, chartDate, phoneNumber };
