import * as yup from 'yup';
import { SchemaObjectDescription, SchemaInnerTypeDescription } from 'yup/lib/schema';
import { FormValidation, FormValidationContext } from 'types';

const createSchema = ({
  t,
  values,
  initialValues,
  validation,
}: FormValidationContext & {
  validation: Nullable<FormValidation>;
}): yup.ObjectSchema<any> => yup.object().shape(validation ? validation({ t, values, initialValues }) : {});

const FORM_ARRAY_FIELD_INDEX_NAME_SUBSTITUTION = '[n]';

const getRequiredFieldNames = ({ fields }: SchemaObjectDescription & SchemaInnerTypeDescription): string[] =>
  !fields
    ? ([] as string[])
    : Object.entries(fields).reduce((result, [name, description]) => {
        const { tests, innerType } = description as SchemaObjectDescription & SchemaInnerTypeDescription;
        const isArrayField = Boolean(innerType);

        // @ts-ignore
        const isFieldRequired = tests.some(({ name }) => name === 'required');

        const nextResult = isFieldRequired ? [...result, name] : result;

        return isArrayField
          ? [
              ...nextResult,
              ...getRequiredFieldNames(innerType as SchemaObjectDescription).map(
                (requiredArrayField) => `${name}${FORM_ARRAY_FIELD_INDEX_NAME_SUBSTITUTION}.${requiredArrayField}`,
              ),
            ]
          : nextResult;
      }, [] as string[]);

export { createSchema, getRequiredFieldNames, FORM_ARRAY_FIELD_INDEX_NAME_SUBSTITUTION };
