import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { nullable } from 'stores/mst-types';
import { isEmpty } from 'utils/is';
import { OrganizationBuildingOverviewApi } from 'types';
import BaseModel from 'stores/models/base';
import { MSTAddStaticMethods } from 'stores/utils';

const staticMethods = {
  fromResponseData: (data: OrganizationBuildingOverviewApi): OrganizationBuildingOverviewSnapshot => ({
    ...pick(data, ['name', 'link', 'state', 'city', 'neighborhood']),
    id: String(data.id),
    minPrice: isEmpty(data.min_price) ? null : data.min_price,
    maxPrice: isEmpty(data.min_price) ? null : data.max_price,
    bedroomCount: data.bedroom_count,
  }),
};

const OrganizationBuildingOverview = BaseModel.named('OrganizationBuildingOverview').props({
  id: types.string,
  name: types.string,
  link: types.string,
  state: types.string,
  city: types.string,
  neighborhood: types.string,
  minPrice: nullable(types.string),
  maxPrice: nullable(types.string),
  bedroomCount: types.array(types.string),
});

export type OrganizationBuildingOverviewInstanceType = Instance<typeof OrganizationBuildingOverview>;
export type OrganizationBuildingOverviewSnapshot = SnapshotOut<typeof OrganizationBuildingOverview>;

export default MSTAddStaticMethods(OrganizationBuildingOverview, staticMethods);
