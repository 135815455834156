import { v4 as uuidv4 } from 'uuid';
import { address } from 'faker';
import {
  UserApi,
  CampaignDb,
  BarChartItemApi,
  PieChartItemApi,
  BuildingApi,
  ListingApi,
  OptionApi,
  TagSelectOptionApi,
  GeoTargetingValueApi,
} from 'types';
import { storage } from 'utils/storage';
import { Collection } from './collection';
import { STORAGE_DATABASE_KEY, createAmenityOptions, CampaignReport } from './helper';

type BarReportItem = BarChartItemApi & CampaignReport;
type PieReportItem = PieChartItemApi & CampaignReport;

export class Database {
  version = 8;

  users = new Collection<UserApi & { password: string; jwt: string }>();

  campaigns = new Collection<CampaignDb>();

  ageReports = new Collection<BarReportItem>();

  regionReports = new Collection<BarReportItem>();

  totalClickReports = new Collection<PieReportItem>();

  totalImpressionReports = new Collection<PieReportItem>();

  listingOptions = new Collection<OptionApi & { id: string }>();

  openHousesOptions = new Collection<OptionApi & { id: string }>();

  buildings = new Collection<BuildingApi>();

  listings = new Collection<ListingApi>();

  geoTargetingOptions = new Collection<GeoTargetingValueApi & { id: string }>();

  buildingAmenityOptions = new Collection<TagSelectOptionApi & { id: string }>();

  listingAmenityOptions = new Collection<TagSelectOptionApi & { id: string }>();

  async init(): Promise<void> {
    await this.resetIfNewVersion();
    await this.saveVersion();

    await this.users.init({ name: 'users' });
    await this.campaigns.init({ name: 'campaigns' });
    await this.ageReports.init({ name: 'ageReports' });
    await this.regionReports.init({ name: 'regionReports' });
    await this.totalClickReports.init({ name: 'totalClickReports' });
    await this.totalImpressionReports.init({
      name: 'totalImpressionsReports',
    });

    await this.listingOptions.init({
      name: 'listingOptions',
    });
    await this.openHousesOptions.init({
      name: 'openHousesOptions',
    });
    await this.buildings.init({
      name: 'buildings',
    });

    await this.geoTargetingOptions.init({
      name: 'geoTargetingOptions',
    });

    await this.buildingAmenityOptions.init({
      name: 'buildingAmenityOptions',
    });

    await this.listingAmenityOptions.init({
      name: 'listingAmenityOptions',
    });

    await this.listings.init({
      name: 'listings',
    });

    await this.users.seed({
      first_name: 'test',
      last_name: 'test',
      email: 'test@test.com',
      password: 'admin',
      jwt: uuidv4(),
      main_photo: null,
      contact_phone: '',
      bio: '',
      website: '',
      featured_photos: [],
      organization_id: undefined,
      organization_kind_name: undefined,
      facebook: {
        is_connected: false,
        facebook_access_token_expired_date: null,
      },
      state: 4,
      city: 1234,
      supported_organization: null,
      supported_user: null,
      profile_id: '1',
    });

    await this.openHousesOptions.seed(
      Array.from({ length: 10 }, () => ({
        id: uuidv4(),
        label: `${address.streetAddress()}, ${address.secondaryAddress()}`,
        value: uuidv4(),
      })),
    );

    await this.buildingAmenityOptions.seed(createAmenityOptions());
    await this.listingAmenityOptions.seed(createAmenityOptions());
  }

  async resetIfNewVersion(): Promise<void> {
    const database = (await storage.load(STORAGE_DATABASE_KEY)) || {};

    if (this.version > database.version) {
      await storage.removeItem(STORAGE_DATABASE_KEY);
    }
  }

  async saveVersion(): Promise<void> {
    const database = (await storage.load(STORAGE_DATABASE_KEY)) || {};

    database.version = this.version;

    await storage.save(STORAGE_DATABASE_KEY, database);
  }
}

const database = new Database();

export default database;
