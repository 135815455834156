import React, { useMemo, useState, useCallback, useRef } from 'react';
import { withModal } from 'hocs/with-modal';
import { useModalProps, useTranslation } from 'hooks';
import { useDebounce, useWindowSize } from 'react-use';
import { GridLayout } from 'components/common/grid-layout';
import { Crop, CropUnitProps } from 'components/common/crop';
import { Form } from 'components/common/form/form';
import { ImageUploaderNotification } from 'components/common/form/image-uploader/image-uploader-notification';
import { Box } from 'components/common/box';
import { constants } from './constants';
import { getAspect, centerAspectCrop, getImageName, imageToFile } from './utils';
import { ImageEdit } from './image-edit';
import { ImageEditModalProps } from './types';
import styles from './styles.module.scss';

export const ImageEditModal = withModal<ImageEditModalProps>()(
  ({ image, index, onEdit, originalValue, name, ...props }) => {
    const { t } = useTranslation();

    const { width: windowWidth } = useWindowSize();

    const imageRef = useRef<HTMLImageElement | null>(null);

    const [crop, setCrop] = useState<CropUnitProps>();
    const [scale, setScale] = useState(100);
    const [aspect, setAspect] = useState(1);

    const tooltip = useMemo(() => {
      if (name === constants.logo) {
        return t('ImageEdit.TooltipLogo');
      }
      return t('ImageEdit.Tooltip');
    }, [name, t]);

    useModalProps(
      useMemo(
        () => ({
          title: t('ImageEdit.Title'),
          tooltip,
          titleProps: { textAlign: 'center' },
          width: 1363,
          headerProps: {
            marginBottom: 20,
          },
        }),
        [t, tooltip],
      ),
    );

    const updateCrop = useCallback(() => {
      if (imageRef?.current) {
        const { width, height } = imageRef.current;

        setCrop(centerAspectCrop(width, height, aspect));
      }
    }, [imageRef, aspect]);

    useDebounce(
      () => {
        updateCrop();
      },
      50,
      [windowWidth, updateCrop],
    );

    const footer = useMemo(
      () => ({
        cancelButtonAvailable: false,
        submitText: t('ImageEdit.SaveButton'),
      }),
      [t],
    );

    const handleToggleAspectClick = ({ target: { value } }: { target: { value: string } }) => {
      const imageEl = imageRef?.current;

      if (!imageEl) {
        return;
      }

      const aspect = getAspect(value, name);
      const { width, height } = imageEl;

      const crop = centerAspectCrop(width, height, aspect);

      setAspect(aspect);
      setCrop(crop);
    };

    const imageName = useMemo(() => {
      const prefixToPreventCaching = Date.now();
      return `${prefixToPreventCaching}-${getImageName(originalValue)}`;
    }, [originalValue]);

    const onSubmit = useCallback(async () => {
      const file = await imageToFile({
        imageEl: imageRef.current,
        crop,
        scale,
        name: imageName,
      });

      onEdit({ index, file });
    }, [crop, imageRef, scale, imageName, onEdit, index]);

    return (
      <Form onSubmit={onSubmit} footer={footer}>
        {name !== constants.logo ? <ImageUploaderNotification marginBottom={30} /> : null}
        <GridLayout gridTemplateColumns='1fr 2fr' column={2} gridColumnGap={70} marginBottom={20} {...props}>
          <ImageEdit scale={scale} setScale={setScale} handleToggleAspectClick={handleToggleAspectClick} name={name} />
          <Box className={styles['cropper-container']}>
            <Crop crop={crop} onChange={setCrop} aspect={aspect} onComplete={setCrop} locked ruleOfThirds>
              <img
                ref={imageRef}
                src={image}
                crossOrigin='anonymous'
                style={{
                  transform: `scale(${scale / 100})`,
                  minWidth: '100%',
                }}
                alt='crop'
                onLoad={() => updateCrop()}
              />
            </Crop>
          </Box>
        </GridLayout>
      </Form>
    );
  },
);
