import { useCallback } from 'react';
import { UploadProps } from 'antd';
import { useField, useTranslation } from 'hooks';
import { errorMessage } from 'components/common/message';
import config from 'config';
import { checkFileExtension } from 'utils/files';
import { DropAreaProps, ImageUploaderValueItem } from '../types';

const { options } = config;

const getValueIndex = (value: Nullable<{ isOld: Nullable<boolean> }[]>, indexOfNonOldValueItems: number): number => {
  let nonOldValueItemIndex = -1;

  if (!value) {
    return nonOldValueItemIndex;
  }

  for (let i = 0, l = value.length || 0; i < l; i++) {
    const { isOld } = value[i];
    if (!isOld) {
      nonOldValueItemIndex += 1;
    }

    if (nonOldValueItemIndex === indexOfNonOldValueItems) {
      return i;
    }
  }

  return -1;
};

export const useDropAreaProps = ({
  maxAmount = options.imageUploader.amountLimit,
  fileSizeLimit = options.imageUploader.fileSizeLimit,
  value: valueFromProps,
  onChange: onChangeFromProps,
  multiple,
  ...props
}: DropAreaProps): Pick<ReturnType<typeof useField>, 'componentProps'> &
  Pick<UploadProps, 'beforeUpload' | 'name'> &
  Pick<DropAreaProps, 'value'> & {
    onRemove: (originalValue: ImageUploaderValueItem) => void;
    onEditImage: (options: { index: number; file: ImageUploaderValueItem }) => void;
  } => {
  const { t } = useTranslation();

  const { field, componentProps } = useField({
    value: valueFromProps,
    onChange: onChangeFromProps,
    ...props,
  });

  const { accept } = componentProps;

  const { name, onChange, onBlur, value } = field;

  const beforeUpload: UploadProps['beforeUpload'] = useCallback(
    (file) => {
      const nextValue = [...(multiple && value ? value : []), file].filter((item) =>
        item && 'isOld' in item ? !item.isOld : true,
      );

      if (!checkFileExtension({ file, accept })) {
        errorMessage(
          t('ImageUploader.WrongExtensionError', {
            extensions: accept,
          }),
        );
        return false;
      }

      if (nextValue.length > maxAmount) {
        errorMessage(t('ImageUploader.AmountLimitExceededError'));
        return false;
      }

      if (file.size > fileSizeLimit) {
        errorMessage(t('ImageUploader.FileSizeLimitExceededError'));
        return false;
      }

      const event = {
        target: { name, value: nextValue },
      };

      onChange(event);

      setTimeout(() => {
        onBlur(event);
      });

      return false;
    },
    [multiple, value, accept, maxAmount, fileSizeLimit, name, onChange, t, onBlur],
  );

  const onRemove = useCallback(
    (originalValue: ImageUploaderValueItem) => {
      const nextValue = value && value.filter((valueItem: ImageUploaderValueItem) => valueItem !== originalValue);

      const event = {
        target: {
          name,
          value: nextValue.length ? nextValue : undefined,
        },
      };

      onChange(event);

      setTimeout(() => {
        onBlur(event);
      });
    },
    [name, value, onChange, onBlur],
  );

  const onEditImage = useCallback(
    ({ index, file }: { index: number; file: ImageUploaderValueItem }) => {
      const processedIndex = getValueIndex(value, index);
      const nextValue = (value as DropAreaProps['value'])?.map((item, itemIndex) =>
        itemIndex === processedIndex ? file : item,
      );

      const event = {
        target: {
          name,
          value: nextValue?.length ? nextValue : undefined,
        },
      };

      onChange(event);

      setTimeout(() => {
        onBlur(event);
      });
    },
    [name, value, onChange, onBlur],
  );

  return {
    value,
    componentProps,
    beforeUpload,
    onRemove,
    onEditImage,
    name,
  };
};
