import { flow, getRunningActionContext, getRoot, types, IType } from 'mobx-state-tree';
// eslint-disable-next-line import/no-unresolved
import { FlowReturn } from 'mobx-state-tree/dist/core/flow';
import * as functions from 'utils/functions';
import {
  isBadRequestError,
  isNotFoundRequestError,
  isTooManyRequestsError,
  isUnauthorizedError,
} from 'services/api/errors';
import { AxiosError } from 'axios';
import { History } from 'history';
import { StatusEnum } from './enums';

interface ApiFlowOptions {
  statusName?: string;
  skipDone?: boolean;
  isUpdate?: boolean;
  handleNotFound?: boolean;
  notFoundUpdateFallbackUrl?: Nullable<string> | (() => Nullable<string>);
  notFoundUpdateAlert?: string;
  formName?: string;
  successAlert?: string;
  errorAlert?: string;
  holderId?: string;
}

type apiFlowType = <R, Args extends any[] = any[]>(
  generator: (...args: Args) => Generator<Promise<any>, R, any>,
  options?: ApiFlowOptions,
) => (...args: Args) => Promise<FlowReturn<R>>;

const lookupParentContext = (actionContext: any): any => {
  const parentContext = actionContext?.parentEvent?.context || actionContext?.parentActionEvent?.context;

  return parentContext || actionContext?.context;
};

// @ts-ignore
const apiFlow: apiFlowType = (generator, options = {} as ApiFlowOptions) => {
  const {
    statusName: resourceName,
    skipDone,
    isUpdate,
    handleNotFound,
    formName,
    successAlert,
    errorAlert = successAlert ? 'Common.Alerts.Error' : undefined,
    holderId,
    notFoundUpdateFallbackUrl,
    notFoundUpdateAlert = 'Common.Alerts.NotFoundUpdate',
  } = options;

  // @ts-ignore
  return flow(function* apiFlowGenerator(...args) {
    const actionContext = getRunningActionContext();
    const parentContext = lookupParentContext(actionContext);

    const setStatus = parentContext?.setStatus || functions.noop;
    const pendingStatus = isUpdate ? StatusEnum.PendingUpdate : StatusEnum.PendingLoad;
    const isRunningInside = Boolean(actionContext?.parentActionEvent);

    const root = getRoot<any>(parentContext);

    try {
      if (formName) {
        root.formErrors.resetFormServerErrors({ formName });
        root.formStates.setFormSubmitting({
          formName,
          isSubmitting: true,
        });
      }

      if (!isRunningInside) {
        setStatus(pendingStatus, resourceName);
      }

      const result = yield* generator(...args);

      if (!isRunningInside && !skipDone) {
        setStatus(StatusEnum.Done, resourceName);
      }

      if (successAlert) {
        root.session.showSuccess(successAlert);
      }
      return result;
    } catch (err) {
      const error = err as AxiosError;

      setStatus(StatusEnum.Error, resourceName);
      console.log(error);

      const notFound = isNotFoundRequestError(error);

      const notFoundUpdateFallbackUrlProcessed =
        typeof notFoundUpdateFallbackUrl === 'function' ? notFoundUpdateFallbackUrl() : notFoundUpdateFallbackUrl;

      if (notFoundUpdateFallbackUrlProcessed && notFound) {
        const history = root.session.services.get('history') as History;
        root.session.showError(notFoundUpdateAlert);
        setTimeout(() => {
          history.replace(notFoundUpdateFallbackUrlProcessed);
        });
        throw error;
      }

      if (handleNotFound && notFound) {
        root.notFoundPage.setVisible(true);
        return undefined;
      }

      if (errorAlert) {
        root.session.showError(errorAlert, undefined, holderId);
      }

      if (formName) {
        if ((isBadRequestError(error) || isUnauthorizedError(error) || notFound) && error.response.data) {
          root.formErrors.setFormBadRequestError({
            formName,
            error,
          });
        } else if (isTooManyRequestsError(error)) {
          root.formErrors.setFormServerErrors({
            formName,
            isRateLimitExceeded: true,
            responseError: error,
          });
        } else {
          root.formErrors.setFormServerErrors({
            formName,
            isSomethingWentWrong: true,
            responseError: error,
          });
        }

        throw error;
      }

      if (isRunningInside) {
        throw error;
      }

      return undefined;
    } finally {
      if (formName) {
        root.formStates.setFormSubmitting({
          formName,
          isSubmitting: false,
        });
      }
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const nullable = <T, R, S>(type: IType<T, R, S>, defaultValue: any = undefined) =>
  types.optional(types.maybeNull(types.maybe(type)), defaultValue, [undefined, null]);

const any = types.union(types.string, types.number, types.boolean);

const stringDate = types.custom<string | number | Date, Date>({
  name: 'StringDate',
  isTargetType: (v) => typeof v === 'number' || typeof v === 'string' || v instanceof Date,
  fromSnapshot(value: string | number | Date): Date {
    return new Date(value);
  },
  toSnapshot(value: Date): string {
    return new Date(value).toJSON();
  },
  getValidationMessage(snapshot: string | number | Date): string {
    return `${snapshot} is invalid`;
  },
});

export { apiFlow, nullable, any, stringDate };
