import { types, cast, toGenerator } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { Pagination } from 'types';
import BuildingOverview from './building-overview';
import BuildingPropertyTypeOptions from './building-property-type-options';

const Buildings = Base.named('Buildings')
  .props({
    entries: types.optional(types.array(BuildingOverview), []),
    count: types.optional(types.number, 0),
    buildingPropertyTypeOptions: types.optional(BuildingPropertyTypeOptions, {}),
  })
  .views((self) => ({
    get buildingsList() {
      return [...self.entries].map(({ propertyType, ...building }: any) => ({
        ...building,
        propertyType: this.getBuildingPropertyTypePresentation(propertyType),
      }));
    },

    getBuildingPropertyTypePresentation(propertyTypeValue: string): Nullable<string> {
      const option = self.buildingPropertyTypeOptions.entries.find(({ value }) => value === propertyTypeValue);

      return option?.label;
    },
  }))
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: Pagination) {
      const response = yield* toGenerator(self.api.getBuildings(options));
      const { data, count } = fromListResponse({
        response: response.data,
      });
      self.entries = cast(data.map(BuildingOverview.fromResponseData));
      self.count = count;
    }),

    fetchBuildingPropertyTypeOptions: self.buildingPropertyTypeOptions.fetch,

    destroy() {
      self.entries = cast([]);
      self.count = 0;
      self.buildingPropertyTypeOptions.destroy();
    },
  }));

export default Buildings;
