import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import { CampaignApi, PostCampaignApi, DateRangeFormValue, GeoTargetingValueApi, DateRangeFormStartValue } from 'types';
import BaseModel from 'stores/models/base';
import DateRange, { DateStart } from 'stores/models/date-range';

import FacebookAd from 'stores/models/ads/facebook-ad';
import InstagramAd from 'stores/models/ads/instagram-ad';
import GoogleAdWordsAd from 'stores/models/ads/google-ad-words-ad';
import GoogleAdsAd from 'stores/models/ads/google-ads-ad';
import { nullable } from 'stores/mst-types';
import { MSTAddStaticMethods } from 'stores/utils';
import { serializeDateRange, serializeStartDate } from 'utils/serializers';
import {
  deserializeDateRange,
  deserializeStatus,
  deserializeType,
  deserializeCampaignTargetKind,
  deserializeGoogleRecentErrors,
  deserializeDateStart,
} from 'utils/deserializers';
import { excludeEmpty } from 'utils/objects';
import { isArrayOfUndefined, isEmptyObject } from 'utils/is';
import config from 'config';
import { Variant } from 'types/ab-testing';
import CampaignStatus from './campaign-status';
import CampaignType from './campaign-type';
import CampaignGeoTarget from './campaign-geotarget';

const { statuses } = config.api.constants.campaigns;

export interface CampaignFormValues
  extends Pick<
    CampaignSnapshot,
    | 'name'
    | 'targetId'
    | 'fbPageId'
    | 'instagramAccountId'
    | 'fbPixelId'
    | 'fbCustomAudienceId'
    | 'googleClientId'
    | 'fbDailyBudget'
    | 'adsDailyBudget'
    | 'adwordsDailyBudget'
    | 'facebookAd'
    | 'instagramAd'
    | 'googleAdWordsAd'
    | 'googleAdsAd'
  > {
  status: CampaignSnapshot['status']['id'];
  runDates: DateRangeFormValue;
  runDate: DateRangeFormStartValue;
  typeOfDatePicker: boolean;
  openHouseRunDates?: DateRangeFormValue;
  geoTargeting: Nullable<GeoTargetingValueApi[]>;
  type?: CampaignSnapshot['type']['id'];
  targetKind?: NonNullable<CampaignSnapshot['targetKind']>['id'];
  isABTesting?: boolean;
  variantAbTesting?: Variant;
}

const staticMethods = {
  fromResponseData: (data: CampaignApi): CampaignSnapshot => ({
    id: String(data.id),
    status: deserializeStatus(data.status),
    type: deserializeType(data.kind),
    name: data.name,
    typeOfDatePicker: !!data.run_dates?.end_date,
    targetId: data.target,
    fbPageId: data.fb_page_id,
    instagramAccountId: data.instagram_acc_id,
    fbPixelId: data.fb_pixel_id,
    fbCustomAudienceId: data.fb_custom_audience_id,
    googleClientId: data.google_client_id,
    targetKind: data.target_kind
      ? deserializeCampaignTargetKind({
          value: data.target_kind,
          campaignKind: data.kind,
        })
      : undefined,
    fbDailyBudget: Number(data.fb_daily_budget),
    adsDailyBudget: Number(data.ads_daily_budget),
    adwordsDailyBudget: Number(data.adwords_daily_budget),
    geoTargeting: data.geotargets ? data.geotargets.map(CampaignGeoTarget.fromResponseData) : [],
    isEntityLocationChanged: data.is_entity_location_changed,
    runDates: data.run_dates?.end_date
      ? (deserializeDateRange(data.run_dates) as CampaignSnapshot['runDates'])
      : { from: null, to: null },
    runDate: !data.run_dates?.end_date
      ? (deserializeDateStart(data.run_dates) as CampaignSnapshot['runDate'])
      : { from: null },
    openHouseRunDates: deserializeDateRange(data.open_house_run_dates) as CampaignSnapshot['openHouseRunDates'],
    facebookAd: data.facebook_ad && FacebookAd.fromResponseData(data.facebook_ad),
    instagramAd: data.instagram_ad && InstagramAd.fromResponseData(data.instagram_ad),
    googleAdWordsAd: data.google_adwords_ad && GoogleAdWordsAd.fromResponseData(data.google_adwords_ad),
    googleAdsAd: data.google_ads_ad && GoogleAdsAd.fromResponseData(data.google_ads_ad),
    isKeywordsUpdated: data.is_keyword_updated,
    isBuildingUpdated: data.is_building_updated,
    googleRecentError: data.google_recent_error ? deserializeGoogleRecentErrors(data.google_recent_error) : null,
  }),

  toRequestData: (data: Partial<CampaignFormValues>): Partial<PostCampaignApi> => {
    const {
      name,
      fbDailyBudget,
      adsDailyBudget,
      adwordsDailyBudget,
      runDates,
      runDate,
      openHouseRunDates,
      geoTargeting,
      facebookAd,
      instagramAd,
      googleAdWordsAd,
      googleAdsAd,
      typeOfDatePicker,
    } = data;

    return excludeEmpty({
      ...pick(data, ['status']),
      name: name ?? undefined,
      target: data.targetId as string,
      fb_page_id: data.fbPageId as string,
      fb_pixel_id: data.fbPixelId as string,
      fb_custom_audience_id: data.fbCustomAudienceId as string,
      instagram_acc_id: data.instagramAccountId as string,
      google_client_id: data.googleClientId as string,
      target_kind: data.targetKind as string,
      geotargets: geoTargeting?.length ? geoTargeting.map(CampaignGeoTarget.toRequestData) : [],
      fb_daily_budget: fbDailyBudget ? Number(fbDailyBudget) : undefined,
      ads_daily_budget: adsDailyBudget ? Number(adsDailyBudget) : undefined,
      adwords_daily_budget: adwordsDailyBudget ? Number(adwordsDailyBudget) : undefined,
      run_dates: typeOfDatePicker
        ? runDates && !isArrayOfUndefined(runDates)
          ? serializeDateRange(runDates)
          : undefined
        : runDate
        ? serializeStartDate(runDate)
        : undefined,

      kind: data.type,
      open_house_run_dates:
        openHouseRunDates && !isArrayOfUndefined(openHouseRunDates) ? serializeDateRange(openHouseRunDates) : undefined,
      facebook_ad:
        facebookAd && !isEmptyObject(excludeEmpty(facebookAd)) ? FacebookAd.toRequestData(facebookAd) : undefined,
      instagram_ad:
        instagramAd && !isEmptyObject(excludeEmpty(instagramAd)) ? InstagramAd.toRequestData(instagramAd) : undefined,
      google_adwords_ad:
        googleAdWordsAd && !isEmptyObject(excludeEmpty(googleAdWordsAd))
          ? GoogleAdWordsAd.toRequestData(googleAdWordsAd)
          : undefined,
      google_ads_ad:
        googleAdsAd && !isEmptyObject(excludeEmpty(googleAdsAd)) ? GoogleAdsAd.toRequestData(googleAdsAd) : undefined,
    });
  },
};

const Campaign = BaseModel.named('Campaign')
  .props({
    id: types.string,
    typeOfDatePicker: types.optional(types.boolean, false),
    name: nullable(types.string),
    targetId: nullable(types.string),
    fbPageId: nullable(types.string),
    instagramAccountId: nullable(types.string),
    fbPixelId: nullable(types.string),
    fbCustomAudienceId: nullable(types.string),
    googleClientId: nullable(types.string),
    status: CampaignStatus,
    type: CampaignType,
    targetKind: nullable(CampaignType),
    fbDailyBudget: nullable(types.number),
    adsDailyBudget: nullable(types.number),
    adwordsDailyBudget: nullable(types.number),
    runDates: types.optional(DateRange, {}),
    runDate: types.optional(DateStart, {}),
    openHouseRunDates: types.optional(DateRange, {}),
    geoTargeting: types.array(CampaignGeoTarget),
    isEntityLocationChanged: types.maybe(types.boolean),
    facebookAd: nullable(FacebookAd),
    instagramAd: nullable(InstagramAd),
    googleAdWordsAd: nullable(GoogleAdWordsAd),
    googleAdsAd: nullable(GoogleAdsAd),
    isKeywordsUpdated: types.maybe(types.boolean),
    isBuildingUpdated: types.maybe(types.boolean),
    googleRecentError: nullable(
      types.model({
        callPhoneNumberNotSupportedForCountry: types.string,
      }),
    ),
  })
  .views((self) => ({
    get statusId() {
      return self.status.id;
    },

    get isPosted() {
      return [statuses.live.id, statuses.paused.id].includes(this.statusId);
    },

    get isDraft() {
      return this.statusId === statuses.draft.id;
    },
  }))
  .actions((self) => ({
    resetTarget() {
      self.facebookAd = null;
      self.instagramAd = null;
      self.googleAdWordsAd = null;
      self.googleAdsAd = null;
    },
  }));

export type CampaignInstanceType = Instance<typeof Campaign>;
export type CampaignSnapshot = SnapshotOut<typeof Campaign>;

export default MSTAddStaticMethods(Campaign, staticMethods);
