import { BuildingApi, CampaignApi } from 'types';
import { ABTestingRequestApi, VariantRequestApi } from 'types/ab-testing';
import { BaseHttpClient } from '../base-http-client';
import { urls } from '../api-urls';
import { GetABTestingResponse, GetABTestingStatusResponse, GetABTestingResultResponse } from '../response-types';

export const createABTestingApi = (http: BaseHttpClient) => ({
  patchABTesting({ id, data }: Pick<CampaignApi, 'id'> & { data: Partial<ABTestingRequestApi> }) {
    return http.post(urls.abTesting.patchABTesting.url.replace('{id}', id), data);
  },

  getAbTestingVariants({ id, data }: Pick<BuildingApi, 'id'> & { data: Partial<VariantRequestApi> }) {
    return http.post<GetABTestingResponse>(urls.abTesting.getABTestingVariants.url.replace('{id}', id), data);
  },

  getABTestingResult({ id }: Pick<CampaignApi, 'id'>) {
    return http.get<GetABTestingResultResponse>(urls.abTesting.getABTestingResults.url.replace('{id}', id));
  },

  getABTestingStatus({ id }: Pick<CampaignApi, 'id'>) {
    return http.get<GetABTestingStatusResponse>(urls.abTesting.getABTestingStatus.url.replace('{id}', id));
  },
});
