import { types, cast, SnapshotOut, Instance, getRoot } from 'mobx-state-tree';
import Base from 'stores/models/base';
import Option from 'stores/models/option';
import { apiFlow } from 'stores/mst-types';

const BuildingPropertyTypeOptions = Base.named('BuildingPropertyTypeOptions')
  .props({
    entries: types.optional(types.array(Option), []),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch() {
      const { common } = getRoot<any>(self);
      const options = yield common.getBuildingPropertyTypeOptions();

      self.entries = cast(options);
    }),
    destroy() {
      self.entries = cast([]);
    },
  }));

export type BuildingPropertyTypeOptionsSnapshot = SnapshotOut<typeof BuildingPropertyTypeOptions>;
export type BuildingPropertyTypeOptionsInstanceType = Instance<typeof BuildingPropertyTypeOptions>;

export default BuildingPropertyTypeOptions;
