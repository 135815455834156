import { getTimeFromMomentInstance } from 'utils/dates';
import {
  DateRangeFormValue,
  SerializedDateRange,
  TimeRangeFormValue,
  SerializedTimeRange,
  TagSelectOptionApiSerialized,
  TagSelectOption,
  ApartmentParametersFieldFormValueApi,
  ApartmentParametersFieldFormValue,
  Pagination,
  PaginationParamsApi,
  Filtration,
  FiltrationParamsApi,
  ImageFormValueSimplifiedApi,
  ImageFormValue,
  KeywordsGroupFormValue,
  KeywordsGroupFormValueApiSerialized,
  TitleApi,
  DescriptionApi,
  OptionFromValue,
  DateRangeFormStartValue,
  SerializedDateStart,
} from 'types';
import pick from 'lodash.pick';
import { isNewTagSelectCustomOption } from 'components/common/form/tag-select';
import config from 'config';
import { excludeEmpty } from './objects';

type InferDateRange<T extends OptionalItems<DateRangeFormValue>> = {
  start_date: T[0] extends Date ? SerializedDateRange['start_date'] : undefined;
  end_date: T[1] extends Date ? SerializedDateRange['end_date'] : undefined;
};

type InferDateStart<A extends DateRangeFormStartValue> = {
  start_date: A extends Date ? SerializedDateStart['start_date'] : undefined;
};

const serializeTitleOption = (option: OptionFromValue): Pick<TitleApi, 'title_text'> => ({
  title_text: option.label,
});

const serializeDescriptionOption = (option: OptionFromValue): Pick<DescriptionApi, 'description_text'> => ({
  description_text: option.label,
});

const serializeDateRange = <T extends OptionalItems<DateRangeFormValue>>([from, to]: T): InferDateRange<T> =>
  ({
    start_date: from ? from.toJSON() : undefined,
    end_date: to ? to.toJSON() : undefined,
  } as InferDateRange<T>);

const serializeStartDate = <A extends DateRangeFormStartValue>(from: Date): InferDateStart<A> =>
  ({
    start_date: from ? from.toJSON() : undefined,
  } as InferDateStart<A>);

type InferTimeRange<T extends OptionalItems<TimeRangeFormValue>> = {
  from: T[0] extends Date ? SerializedTimeRange['from'] : undefined;
  to: T[1] extends Date ? SerializedTimeRange['to'] : undefined;
};

const serializeTimeRange = <T extends OptionalItems<TimeRangeFormValue>>([from, to]: T): InferTimeRange<T> =>
  ({
    from: from && getTimeFromMomentInstance(from),
    to: to && getTimeFromMomentInstance(to),
  } as InferTimeRange<T>);

const serializeTagSelectOption = (option: TagSelectOption): TagSelectOptionApiSerialized => {
  const isNewCustom = isNewTagSelectCustomOption(option);
  return {
    ...pick(option, ['label']),
    ...(isNewCustom ? {} : { value: option.value }),
  };
};

const serializeTagSelectOptions = (options: TagSelectOption[]): TagSelectOptionApiSerialized[] =>
  options.map(serializeTagSelectOption);

const serializeApartmentParameter = (
  option: ApartmentParametersFieldFormValue,
): ApartmentParametersFieldFormValueApi => ({
  kind: Number(option.type),
  min_price: String(option.minPrice),
  max_price: String(option.maxPrice),
});

const serializeApartmentParameters = (
  options: ApartmentParametersFieldFormValue[],
): ApartmentParametersFieldFormValueApi[] => options.map(serializeApartmentParameter);

const serializePaginationParams = <T>(
  { pagination, ...rest }: Pagination & T = {} as Pagination & T,
): Partial<PaginationParamsApi> & T => {
  if (!pagination) {
    // @ts-ignore
    return rest as unknown as T;
  }

  const { current, pageSize } = pagination;

  return {
    offset: Math.max(current - 1, 0) * pageSize,
    limit: pageSize,
    ...(rest as unknown as T),
  };
};

const serializeSimplifiedImage = (image: ImageFormValue): ImageFormValueSimplifiedApi =>
  image instanceof File ? image : image.src;

const serializeSorting = (sorting: Filtration['sorting']): Nullable<string> =>
  sorting ? ([] as string[]).concat(sorting).join(',') : undefined;

const serializeFiltrationParams = <T>(
  { sorting = `-${config.api.sorting.createdAt}`, ...rest }: Filtration & T = {} as Filtration & T,
): Partial<FiltrationParamsApi> & T => {
  const pagination = serializePaginationParams(rest);

  return excludeEmpty({
    ...pagination,
    ordering: serializeSorting(sorting),
  }) as unknown as Partial<FiltrationParamsApi> & T;
};

const serializeFiltrationParamsWithoutOrdering = <T>(
  { sorting = `-${config.api.sorting.createdAt}`, ...rest }: Filtration & T = {} as Filtration & T,
): Partial<FiltrationParamsApi> & T => {
  const pagination = serializePaginationParams(rest);

  return excludeEmpty({
    ...pagination,
  }) as unknown as Partial<FiltrationParamsApi> & T;
};

const serializeOption = (option: TagSelectOption): TagSelectOptionApiSerialized => {
  const isNewCustom = isNewTagSelectCustomOption(option);
  return {
    ...pick(option, ['label']),
    ...(isNewCustom ? {} : { value: option.value }),
  };
};

const serializeSelectedUser = ({
  organization,
  user,
}: {
  organization: string;
  user: string;
}): {
  supported_organization: {
    id: number;
  };
  supported_user: {
    id: number;
  };
} => ({
  supported_organization: {
    id: Number(organization),
  },
  supported_user: {
    id: Number(user),
  },
});

const serializeKeyword = serializeTagSelectOption;

const serializeKeywordsGroup = ({ group, keywords }: KeywordsGroupFormValue): KeywordsGroupFormValueApiSerialized => ({
  group: serializeOption(group),
  keywords: keywords?.map(serializeKeyword),
});

const serializeKeywordGroups = (groups: KeywordsGroupFormValue[]): KeywordsGroupFormValueApiSerialized[] =>
  groups.map(serializeKeywordsGroup);

export {
  serializeDateRange,
  serializeStartDate,
  serializeTimeRange,
  serializeTagSelectOptions,
  serializeApartmentParameters,
  serializeFiltrationParamsWithoutOrdering,
  serializePaginationParams,
  serializeFiltrationParams,
  serializeSimplifiedImage,
  serializeKeywordGroups,
  serializeSelectedUser,
  serializeTitleOption,
  serializeDescriptionOption,
};
