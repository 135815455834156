import { types, cast, toGenerator } from 'mobx-state-tree';
import Base from 'stores/models/base';
import { apiFlow } from 'stores/mst-types';
import { fromListResponse } from 'services/api';
import { Filtration } from 'types';
import OrganizationBuildingOverview from './organization-building-overview';

const OrganizationBuildings = Base.named('OrganizationBuildings')
  .props({
    entries: types.optional(types.array(OrganizationBuildingOverview), []),
    count: types.optional(types.number, 0),
  })
  .actions((self) => ({
    fetch: apiFlow(function* fetch(options?: Filtration) {
      const response = yield* toGenerator(self.api.getOrganizationBuildings(options));
      const { data, count } = fromListResponse({
        response: response.data,
      });

      self.entries = cast(data.map(OrganizationBuildingOverview.fromResponseData));
      self.count = count;
    }),
    destroy() {
      self.entries = cast([]);
      self.count = 0;
    },
  }));

export default OrganizationBuildings;
