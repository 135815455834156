import React, { memo } from 'react';
import { useTranslation } from 'hooks';
import { Box } from 'components/common/box';
import { Typography } from 'components/common/typography';
import { InfoIcon } from 'components/common/icon';
import { ImageUploaderNotificationProps } from './types';

export const ImageUploaderNotification = memo<ImageUploaderNotificationProps>(({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Box display='flex' {...props}>
      <InfoIcon marginRight={15} />
      <Typography variant='body-7' color='grey5'>
        {t('ImageUploader.Tooltip')}
      </Typography>
    </Box>
  );
});
