import { types } from 'mobx-state-tree';
import { apiFlow } from 'stores/mst-types';
import Base from 'stores/models/base';
import Buildings from 'stores/models/buildings/buildings';
import ReportsOverview from 'stores/models/reports/reports-overview';
import Campaigns from 'stores/models/campaigns/campaigns';
import Listings from 'stores/models/listings/listings';
import { CAMPAIGNS_SORTING } from 'stores/campaigns/campaigns-store';

const listPagination = { pagination: { current: 1, pageSize: 5 } };

export const DashboardStore = Base.named('DashboardStore')
  .props({
    reportsOverview: types.optional(ReportsOverview, {}),
    campaigns: types.optional(Campaigns, {}),
    buildings: types.optional(Buildings, {}),
    listings: types.optional(Listings, {}),
    isInitialized: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get campaignsPending() {
      return self.campaigns.pending;
    },

    get campaignsList() {
      return [...self.campaigns.entries];
    },

    get campaignsCount() {
      return self.campaigns.count;
    },

    get buildingsPending() {
      return self.buildings.pending;
    },

    get buildingsList() {
      return self.buildings.buildingsList;
    },

    get buildingsCount() {
      return self.buildings.count;
    },

    get listingsPending() {
      return self.listings.pending;
    },

    get listingsList() {
      return self.listings.listingsList;
    },

    get listingsCount() {
      return self.listings.count;
    },
  }))
  .actions((self) => ({
    init: apiFlow(function* init() {
      yield Promise.all([
        self.campaigns.fetch({
          ...listPagination,
          sorting: CAMPAIGNS_SORTING,
        }),
        self.buildings.fetch(listPagination),
        self.buildings.fetchBuildingPropertyTypeOptions(),
        self.listings.fetch(listPagination),
        self.listings.fetchListingsPropertyTypeOptions(),
      ]);

      self.isInitialized = true;
    }),

    destroy: () => {
      self.campaigns.destroy();
      self.reportsOverview.destroy();
      self.buildings.destroy();
      self.listings.destroy();

      self.isInitialized = false;
    },
  }));
