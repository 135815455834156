import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import pick from 'lodash.pick';
import {
  ApartmentParametersFieldFormValue,
  BuildingApi,
  ImageFormValue,
  PatchBuildingApi,
  PostBuildingApi,
  TagSelectOption,
} from 'types';
import BaseModel from 'stores/models/base';
import TagOption from 'stores/models/tag-option';
import { MSTAddStaticMethods } from 'stores/utils';
import { nullable } from 'stores/mst-types';
import { deserializeApartmentParameters } from 'utils/deserializers';
import { serializeApartmentParameters, serializeTagSelectOptions } from 'utils/serializers';
import CampaignTarget from '../campaigns/campaign-target';

export interface PostBuildingFormValues {
  name: string;
  state: string;
  city: string;
  zip: string;
  neighborhood: string;
  address: string;
  url: string;
  propertyType: string;
  yardiPropertyId?: number;
  yardiAutoUpdate?: boolean;
  buildingAmenities: TagSelectOption[];
  buildingCompetitors?: TagSelectOption[];
  masstransit?: TagSelectOption[];
  apartmentParameters: ApartmentParametersFieldFormValue[];
  apartmentPrices?: [
    {
      apartmentPricesMin?: number;
      apartmentPricesMax?: number;
    },
  ];
  description: string;
  images: ImageFormValue[];
  initializing_status?: string;
}

export interface PatchBuildingFormValues extends Partial<PostBuildingFormValues> {}

const staticMethods = {
  fromResponseData: (data: BuildingApi): BuildingSnapshot => {
    console.log('Data received:', data);
    return {
      ...CampaignTarget.fromResponseData(data),
      ...pick(data, ['neighborhood', 'address', 'description']),
      state: String(data.state),
      city: String(data.city),
      zip: data.zip_code,
      propertyType: String(data.property_type),
      apartmentParameters: data.apartment_parameters && deserializeApartmentParameters(data.apartment_parameters),
      apartmentPrices: [
        {
          apartmentPricesMin: data?.apartment_min_price ? +data?.apartment_min_price : null,
          apartmentPricesMax: data?.apartment_max_price ? +data?.apartment_max_price : null,
        },
      ],
      buildingAmenities: data.building_amenities?.map(TagOption.fromResponseData),
      buildingCompetitors: data.building_competitors?.map(TagOption.fromResponseData),
      masstransit: data.mass_transit?.map(TagOption.fromResponseData),
      initializing_status: data.initializing_status,
    };
  },

  toPostRequest: (values: PostBuildingFormValues): PostBuildingApi => ({
    ...pick(values, ['name', 'state', 'city', 'neighborhood', 'address', 'url', 'description', 'images']),
    zip_code: values.zip,
    property_type: values.propertyType,
    apartment_parameters: values.apartmentParameters && serializeApartmentParameters(values.apartmentParameters),
    apartment_min_price: values.apartmentPrices && values.apartmentPrices[0]?.apartmentPricesMin,
    apartment_max_price: values.apartmentPrices && values.apartmentPrices[0]?.apartmentPricesMax,
    building_amenities: values.buildingAmenities && serializeTagSelectOptions(values.buildingAmenities),
    building_competitors: values.buildingCompetitors && serializeTagSelectOptions(values.buildingCompetitors),
    mass_transit: values.masstransit && serializeTagSelectOptions(values.masstransit),
  }),

  toPatchRequestData: (values: Partial<PatchBuildingFormValues>): Partial<PatchBuildingApi> =>
    staticMethods.toPostRequest(
      // @ts-ignore
      values as unknown as PatchBuildingFormValues,
    ),
};

const Building = BaseModel.named('Building').props({
  ...CampaignTarget.properties,
  state: types.string,
  city: types.string,
  zip: types.string,
  neighborhood: types.string,
  address: types.string,
  propertyType: types.string,
  apartmentParameters: nullable(
    types.array(
      types.model({
        type: types.string,
        minPrice: types.number,
        maxPrice: types.number,
      }),
    ),
  ),
  apartmentPrices: nullable(
    types.array(
      types.model({
        apartmentPricesMin: nullable(types.number),
        apartmentPricesMax: nullable(types.number),
      }),
    ),
  ),
  buildingAmenities: nullable(types.array(TagOption)),
  buildingCompetitors: nullable(types.array(TagOption)),
  masstransit: nullable(types.array(TagOption)),
  description: nullable(types.string),
  initializing_status: nullable(types.string),
});

export type BuildingInstanceType = Instance<typeof Building>;
export type BuildingSnapshot = SnapshotOut<typeof Building>;

export default MSTAddStaticMethods(Building, staticMethods);
